<template>
  <div class="scan page-container">
    <div class="content-wrapper">
      <div class="text-center" style="padding: 200px 0">Redirect...</div>
    </div>
  </div>
</template>
<script>
  import { ROUTE_NAME } from '@/core/constants';
  import { uiHelper } from '@/core/utils';
  import {
    CONSTANTS_GET_CONSTANTS,
    CONSTANTS_INITIAL_CONSTANTS_STATE
  } from '@/core/store/constants.module';
  import { LOCAL } from '@/core/constants';

  export default {
    name: 'Home',
    data: () => ({
      routeName: ROUTE_NAME,
      androidLink: '',
      iosLink: '',
      huaweiLink: '',
      constants: ''
    }),
    mounted() {
      this.getConstants();
    },
    computed: {
      constantsComplete() {
        return this.$store.state.constants.constants.complete;
      }
    },
    watch: {
      constantsComplete() {
        let response = this.$store.state.constants.constants;
        this.constants = response;
        if (response.complete) {
          this.getQRCodeURL();
          this.initialGetConstantsState();
          console.log(1);
        }
      }
    },
    methods: {
      getConstants() {
        this.$store.dispatch(CONSTANTS_GET_CONSTANTS);
      },
      initialGetConstantsState() {
        this.$store.dispatch(CONSTANTS_INITIAL_CONSTANTS_STATE);
      },
      getQRCodeURL() {
        let clientDeviceOS = uiHelper.getOperatingSystem();
        let key = '';

        if (['Macintosh', 'IOS'].includes(clientDeviceOS)) {
          key = 'IOS';
        } else if (['Huawei'].includes(clientDeviceOS)) {
          key = 'HUAWEI';
        } else {
          key = 'ANDROID';
        }

        let constantsData = JSON.parse(
          uiHelper.getLocalStorage(LOCAL.CONSTANTS)
        );
        let appInfos = constantsData.appInfo;
        for (let i = 0; i < appInfos.length; i++) {
          if (appInfos[i].platform == key) {
            window.location.href = appInfos[i].url;
          }
        }
      }
    }
  };
</script>
